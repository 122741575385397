.full-width-img{
    // min-height: 805px;
    img{
        opacity: 0.5;
        @include media-breakpoint-up(md) {
            opacity: 1;
        }

    }
    .text-block{
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;

        @include media-breakpoint-up(md) {
            margin-right: 80px;
   
            margin-top: -60px;
            margin-bottom: -60px;
        }
        background: #FFFFFF;
        border-radius: 20px;
        padding: 0px 40px 40px 40px;
        // margin: 0px 0px;
    }


    .upper-brush-divider{
        position: relative;
        z-index: -1;
         @include media-breakpoint-up(md) {
            top: -115px;
            z-index: 5;

         }
        @include media-breakpoint-up(lg) {
            z-index: 5;
            top: -170px;
        }
    }

    .lower-brush-divider{
        position: relative;
        z-index: -2;
         @include media-breakpoint-up(md) {
            bottom: -108px;
            z-index: 5;
         }
        @include media-breakpoint-up(lg) {
            z-index: 5;
            bottom: -170px;
        }
    }

}