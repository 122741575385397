.testimonials-section{
    @media (max-width: 600px) {
        .column-responsive {
          flex: 100%;
          max-width: 100%;
        }
      }
    .stars{
        max-width: 180px;
    }
    .review-parent{
        display: flex;
        flex-wrap: wrap;

        .review-column {
            flex: 100%;
            max-width: 100%;
            @include media-breakpoint-up(md) {
                flex: 50%;
                max-width: 50%;
            }
            .review-wrapper{
                border-radius: 20px;
                filter: drop-shadow(0px 32px 50px rgba(0, 0, 0, 0.06));
                padding: 5px;
                @include media-breakpoint-up(md) {
                    padding: 18px;
                }
                img{
                    image-rendering: -webkit-optimize-contrast;
                }
            }
        }
    }

    .red-stroke-right{
        left: 100px;
        top: 265px;
        z-index: -1;
        max-width: 537px;
        position: absolute;

        &.lower{
            top: 2265px;
        }
    }	
    .red-stroke-left{
        max-width: 537px;
        position: absolute;
        right: 20px;
        top: 1160px;
        z-index: -1;

        &.lower{
            top: 3234px;
        }
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-up(xl) {
        }
    }	

}
