// --------------------
// Include Fonts Here
// --------------------


@font-face {
    font-family: 'Avenir-Next';
    src: url('../fonts/AvenirNext-Regular-08.woff2') format('woff2'),
    url('../fonts/AvenirNext-Regular-08.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir-Next';
    src: url('../fonts/AvenirLT-Heavy.woff2') format('woff2'),
    url('../fonts/AvenirLT-Heavy.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Next';
    src: url('../fonts/AvenirNext-Bold-01.woff2') format('woff2'),
    url('../fonts/AvenirNext-Bold-01.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Pro';
    src: url('../fonts/ITCAvantGardeStd-Md.woff2') format('woff2'),
    url('../fonts/ITCAvantGardeStd-Md.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Pro';
    src: url('../fonts/ITCAvantGardeStd-BoldCn.woff2') format('woff2'),
    url('../fonts/ITCAvantGardeStd-BoldCn.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Pro';
    src: url('../fonts/ITCAvantGardeStd-Bold.woff2') format('woff2'),
    url('../fonts/ITCAvantGardeStd-Bold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}



$headings-font: 'ITC Avant Garde Gothic Pro', sans-serif;
$body-font: 'Avenir-Next', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    @include font-smoothing('on');
    font-size: 2rem; //Laptop: 20px, Ipads: 18px, Mobiles: 16px
    line-height: 1.6;
    font-family: $body-font;
}

p {
    font-family: 'Avenir-Next';
    margin-bottom: 1.6rem;
    font-style: normal;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #000000;

    // font-size: 3.9rem;
    @include media-breakpoint-up(lg) {
        font-size: 1.9rem;
    }

}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.3;
    margin-bottom: 2rem;
    font-family: $headings-font;
    font-weight: bold;
    text-transform: capitalize;
}

h1, .h1 {
    // font-size: 4.5rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px

    font-family: 'ITC Avant Garde Gothic Pro';
    font-style: normal;
    font-weight: 500;
    // font-size: 45px;
    line-height: 108.5%;
    text-align: center;
    color: #222326;
    font-size: 30px;


    @include media-breakpoint-up(lg) {
        font-size: 4.5rem;
    }

}

h2, .h2 {
    // font-size: 4.6rem;  //Laptop: 46px, Ipads: 41.4px, Mobiles: 36.8px

    font-family: 'ITC Avant Garde Gothic Pro';
    font-style: normal;
    font-weight: 500;
    line-height: 112%;
    color: #222326;
    padding-top: 3rem;
    font-size: 25px;

    @include media-breakpoint-up(lg) {
        font-size: 3.9rem;
    }
    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: calc(50% - 40px);
    //     width: 80px;
    //     height: 1px;
    //     background: $primary;
    // }
    // @include media-breakpoint-up(lg) {
    //     &.text-lg-left:before {
    //         left: 0;
    //     }
    // }
    // &.text-white:before {
    //     background: white;
    // }
}

h3, .h3 {
    // font-size: 3rem;  //Laptop: 30px, Ipads: 27px, Mobiles: 24px

    font-family: 'ITC Avant Garde Gothic Pro';
    font-style: normal;
    font-weight: 500;
    line-height: 112%;
    text-align: center;
    color: #222326;
    
    font-size: 20px;
    @include media-breakpoint-up(lg) {
        font-size: 3rem;
    }

}

h4, .h4 {
    // font-size: 2.4rem;  //Laptop: 24px, Ipads: 21.6px, Mobiles: 19.2px
    font-family: 'Avenir-Next';
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.02em;
    font-size: 15px;

    @include media-breakpoint-up(lg) {
        font-size: 1.9rem;
    }
}

h5, .h5 {
    // font-size: 2rem;  //Laptop: 20px, Ipads: 18px, Mobiles: 16px

    font-family: 'ITC Avant Garde Gothic Pro';
    font-style: normal;
    font-weight: 500;
    line-height: 112%;
    text-align: center;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    color: #222326;
    
    font-size: 15px;
    @include media-breakpoint-up(lg) {
        font-size: 2.0rem;
    }

}

h6, .h6 {
    font-size: 1.8rem;  //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
    
}

strong {
	// font-family: $headings-font;
}

// Hero Specific Font Sizes
.eyebrow {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.095em;
    text-transform: uppercase;
    color: #ED1C24;

}

.subheadline {
    font-size: 2.2rem;
}

hr{
    width: 100px;
    margin-bottom: 2rem;
    border-top: 0.5px solid #FFF200;
    margin: 0 0 2rem 0;
}
.hr-left{
 
}
.hr-centre{
    margin: 0 auto;
}