.table-wrapper{
	background-color: $white;
	box-shadow: 0px 60px 80px -32px rgba(0, 0, 0, 0.06);
	border-radius: 20px;
	padding: 0;
	position: relative;
	z-index: 1;
	@include media-breakpoint-up(md) {
		padding: 0 10px;
	}
}

.checkboxes-section.table{
    .container{
        padding: 0 !important;
        @include media-breakpoint-up(md) {
        
        }
	}
} 
.table {


	.top-row{
		td{
			width: 20%;
			vertical-align: middle;
			border-top: none;
		}
		th{
			p{
				font-style: normal;
				font-weight: 500;
				color: #222326;
				font-family: 'ITC Avant Garde Gothic Pro';
				// font-size: 15px;
				font-size: 1.5rem;
				
				line-height: 15px;
				@include media-breakpoint-up(md) {
					font-size: 20px;
					line-height: 25px;
				}
			}
		}
	}
	.heading-col{
		td{
			width: 20%;
			vertical-align: bottom;
			border-top: none;
		}
		th{
			p{
				font-family: 'Avenir-Next';
				font-weight: 600;
				// font-size: 19px;
				font-size: 1.9rem;
				// line-height: 26px;
				letter-spacing: -0.02em;
				color: #000000;

				line-height: 15px;
				@include media-breakpoint-up(md) {
					line-height: 25px;
				}
			}
		}
		td{
				// font-size: 15px;
				font-size: 1.5rem;
			@include media-breakpoint-up(md) {
				// font-size: 19px;
				font-size: 1.9rem;
			}
		}
	}
	tr {
		border-bottom: 1px solid rgba(0, 0, 0, 0.09);
		th,
		td {
			width: 20%;
			vertical-align: bottom;
			border-top: none;

			padding: 5px;
			@include media-breakpoint-up(md) {
				padding: 20px;
			}
			// img.icon,
			// svg.icon 
			.icon
				{
					svg{
						max-width: 50px;
						padding: 7px;
						@include media-breakpoint-up(md) {
							max-width: 70px;
							padding: 5px;
						}
						@include media-breakpoint-up(lg) {
							max-width: 50px;
							padding: 0px;
						}
		
					}
					&.red-ticked{
						svg{
							max-width: 30px;
						}
					}
					
					&.crossed{
						svg{
							max-width: 30px;
						}
					}
				}
			&.first-column {
				width: 10%;
				@include media-breakpoint-up(md) {
					width: 40%;
				}
				p{
					font-weight: 600;
				}
			}

			&.active-cell {
				position: relative;
				background-color: $bg-table-active-color;
				i {
					color: $white;
				}
				.subtext{
					font-style: normal;
					font-weight: 500;
					font-size: 12.1176px;
					text-align: center;
					color: #222326;
				}
			}
		}

		th,
	
		&:last-child td {
			&.active-cell:before {
				content: "";
				position: absolute;
				width: 100%;
				background-color: $bg-table-active-color;
				left: 0;
				height: 40px;
			}
		}
		&:last-child td.active-cell:before {
			bottom:-30px;
			border-radius: 0 0 10px 10px;
		}
		
		th {
			&.active-cell {
				&:before {
					top:-30px;
					border-radius: 10px 10px 0 0;
				}
			}
		}
	}
	
}