.brands{
    // background: #ffffff;
        .logo{
            max-width: 200px;
            padding:10px 10px;

            img{
                @include media-breakpoint-up(sm) {
                    max-width: 180px;
                }
            }
        }
}