.countdown-timer {
    margin-bottom: 30px;
    .training-text {
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }

    .progressbar {
        width: 100%;
        max-width: 468px;

        height: 24px;
        margin: 5px auto;
        border-radius: 25px;
        padding: 3px;
        box-shadow: inset 0 -1px 1px #929eaa;
        background: #ED1C24;
        div.completed {

            position: relative;
            height: 100%;
            border-radius: 20px 8px 8px 20px;

            background-color: #FFF200;
            &:after {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                border: 20px 8px 8px 20px;
                // background-image: linear-gradient(-45deg, rgba(255, 255, 255, .1) 25%,transparent 0,transparent 50%, rgba(255, 255, 255, .1) 0, rgba(255, 255, 255, .1) 75%,transparent 0,transparent);
                background-size: 50px 50px;
                animation: move 5s linear infinite;
            }
        }
    }
}

@keyframes move {
    0% {
        background-position: 0 0;
    }

    to {
        background-position: 50px 50px;
    }
}
