// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {

    width: 100%;
    border: 0;
    border-radius: 5px;
    margin-bottom: 12px;
    font-size: 17px;
    line-height: 1;
    text-transform: uppercase;
    // letter-spacing: 2px;
    letter-spacing: 0.04em;
    padding: 20px 10px;
    white-space: normal;

    color: #222326;
    transition: background-color .4s ease-in-out;
    background: #FFF200;
    border: 1px solid #FFF200;

    border-radius: 500px;
    // font-family: 'ITC Avant Garde Gothic Pro';
    font-family: 'Avenir-Next';
    font-weight: 600;

    @include media-breakpoint-up(md) {
        padding: 30px;
    }
    &:hover,
    &:focus,
    &:active {
        color: #222326;
        background: #fff;
        border: 1px solid #FFF200;
    }
}

.btn-section {
    max-width: 468px;
    p {
        font-family: 'Avenir-Next';
        width: 90%;
        margin: 0 auto;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
    }
}
//Button wrapper for absolute positioning
.btn-absolute{
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(md) {
        position: absolute;
    
    }
}