.three-steps-section{


    color: #000000;
    h2{
        color: #000000;
        max-width: 600px;
    }
    .wrapper{
        box-shadow: 0px 60px 80px -32px rgba(0, 0, 0, 0.06);
        border-radius: 20px;

        padding-right: 15px;
        padding-left: 15px;
        
        @include media-breakpoint-up(md) {
            padding-left: 40px;
            padding-right: 40px;
            min-height: 400px;
        }
    }
    .icon-wrap{
        margin-bottom: 0px;
        margin-top: 15px;
        background: #fff;
        height: 100px;
        width: 120px;
        border-radius: 100%;

        .icon{
            font-weight: 700;
            padding-top: 5px;
            display: flex;
            justify-content: center;
            align-content: center;
            font-size: 40px;
            color:  #03B882;
        }
    }
    .icon-wrap-mob{
        background: #fff;
        height: 40px;
        width: 40px;
        border-radius: 100%;
        position: absolute;
        left: 22px;
        top: 43px;

        .icon-mob{
            font-weight: 700;
            font-size: 20px;
            line-height: 21px;
            color: #03B882;
            display: flex;
            justify-content: center;
            align-content: center;
            padding-top: 8px;
        }
    }
   .subhead{
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        letter-spacing: -0.02em;
        padding-top: 20px;
        }
        .copy{
            padding: 20px 0;
            font-size: 19px;
            line-height: 26px;
            letter-spacing: -0.02em;
    }
.bg-spacer{
    padding-bottom: 50px;
}
    padding-bottom: 0 !important;
}