.list-section{
    .styled-list{
        li{
            padding: 35px 10px;
            @include media-breakpoint-up(md) {
                padding: 35px 30px;
            }
            background: #FFFFFF;
            box-shadow: 0px 60px 80px -32px rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            img{
                max-width: 58.4px;
            }
            .heading{
                font-weight: 500;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: -0.02em;
                color: #000000;
                padding-bottom: 6px;
            }
            .copy{
                font-size: 19px;
                line-height: 26px;
                letter-spacing: -0.02em;
                color: #000000;
            }

        }
    }
}