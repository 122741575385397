.offer-section{
    background: #F4F3F8;
    h2{
        padding-top: 0;
    }
    .footer-hero{
        img{
            max-width: 1000px;
        }
    }
    .highlight-parent{
         font-family: 'ITC Avant Garde Gothic Pro';
        font-weight: 500;
        font-size: 39px;
        line-height: 112%;
        text-align: center;
        color: #222326;
    }
    .red-underline{
        background-image: url(../images/red-underline.png);
        background-position: center bottom 3px;
        background-repeat: no-repeat;
        background-size: 100% 6px;
    }
    .highlight{
        background-image: url(../images/ylw-highlight.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .red{
            color: #ED1C24;
        }
    }
    .styled-list{
        font-family: 'Avenir-Next';
        padding-top: 5rem;
    }

    .brush-divider{
        position: relative;
        z-index: 5;
       
        bottom: 75px;
        @include media-breakpoint-up(sm) {
            bottom: 100px;
        }
        @include media-breakpoint-up(md) {
            bottom: 145px;
        }
        @include media-breakpoint-up(lg) {
            bottom: 152px;
        }
        @include media-breakpoint-up(xl) {
            bottom: 167px;
        }
    }
}
    
.c-progress {
        position:relative;
        width: 100%;
        max-width: 468px;

        height:21px;
        background:#ED1C24;
        border-radius:100px;

        overflow:hidden;
        border:2px solid #ED1C24;
        
        margin-left:auto;
        margin-right:auto;

        box-shadow:3px 3px 5px hsla(0,0%,100%,.1);
        margin-bottom:13px
   }
   .c-progress__bar {
        // -webkit-transform:skewX(-40deg);
        // transform:skewX(-40deg);
        // -webkit-transform-origin:bottom;
        // transform-origin:bottom;
        padding-right:45%;
        margin-left:-30px;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;

        // background-image:linear-gradient(90deg,#e73a04,#e73a04 33%);
        background: #FFF200;
        background-clip:content-box;
        border-top-left-radius:10px;
        border-bottom-left-radius:100px
   }
   .c-progress__bar.loading {
        -webkit-animation-name:loading;
        animation-name:loading;
        -webkit-animation-duration:40s;
        animation-duration:40s;
        -webkit-animation-iteration-count:infinite;
        animation-iteration-count:infinite;
        -webkit-animation-timing-function:linear;
        animation-timing-function:linear
   }
   @-webkit-keyframes loading {
    0% {
        padding-right:45%
        }
        to {
        padding-right:5%
        }
    }
    @keyframes loading {
        0% {
        padding-right:45%
        }
        to {
        padding-right:5%
        }
   }