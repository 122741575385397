
body.ebook-page {

    .eyebrow{
      padding-top: 25px;
      padding-bottom: 15px;
        @include media-breakpoint-up(lg) {
            padding-top: 50px;
        }
        @include media-breakpoint-up(xl) {
            padding-top: 0;
        }
    }

    .ebook-hero-margin-reduce{
        @include media-breakpoint-up(md) {
            margin-bottom: -60px;
         }
         margin-bottom: -100px;
    }
    img{
        image-rendering: -webkit-optimize-contrast;
    }
	.ebook-footer{
        background: #F4F3F8;
    }
    .underline{
        text-decoration: underline;
    }

    .hero-brush-divider{
        position: relative;
        z-index: 5;
        top: 98px;
       
    }
    .testimonials-section{
        background: linear-gradient(#F4F3F8,#FFFFFF);

        padding: 0px 0px;

        @include media-breakpoint-up(md) {
            padding: 80px 0px;
        }
        @include media-breakpoint-up(lg) {
            padding: 120px 0px;
        }
  
        
    }
    .lower-brush-divider{
        position: relative;
        z-index: 5;
        bottom: 72px;
        @include media-breakpoint-up(sm) {
         }
         @include media-breakpoint-up(md) {
            bottom: 90px;
         }
        @include media-breakpoint-up(lg) {
            bottom: 100px;
        }
    }
    .popup-section{
        .modal-content{
            max-width: 582px;
            padding-bottom: 40px;
        }
        .modal-header{
            font-family: 'ITC Avant Garde Gothic Pro';
            max-width: 420px;
            border-bottom: none;
            text-align: center;
            padding-top: 40px !important; 
            font-weight: 500;
            font-size: 35px;
            line-height: 112%;
            color: #222326;
            @include media-breakpoint-up(md) {

            }
        }
        .modal-body{
            padding: 1rem;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #444B51;
            mix-blend-mode: normal;
            @include media-breakpoint-up(md) {
                padding: 10px 70px !important;
            }
        }
    }
}