.checkboxes-section{
    background: linear-gradient(180deg, #FFFFFF 0%, #F4F3F8 100%);

    .red-stroke-upper{
        top: 65px;
        right: 100px;
        z-index: 0; 
        max-width: 292px;
        position: relative;
        img {
            image-rendering: -webkit-optimize-contrast;
        }
        @include media-breakpoint-up(sm) {
       
        }
    }	
    .red-stroke-lower{
        
        z-index: 0; 
        max-width: 292px;
        position: relative;
        bottom: 65px;
        left: 150px;

        @include media-breakpoint-up(sm) {
            left: 250px;
        }
        @include media-breakpoint-up(md) {
            left: 350px;
        }

        @include media-breakpoint-up(lg) {
            left: 550px;
        }
        @include media-breakpoint-up(xl) {
            bottom: 65px;
            left: 750px;  
        }
    }	

    .three-step-brush-divider{
        position: relative;
        z-index: 5;
        top: 100px;

        @include media-breakpoint-up(md) {
            top: 90px;
        }
        @include media-breakpoint-up(lg) {
            top:120px;
        }
        @include media-breakpoint-up(xl) {
            top: 150px;
        }
    }
    .col-10-table{
        max-width: 95%;
        // padding-left: 5px !important;
        // padding-right: 5px !important;
    }
    .less-margin{
        margin-bottom: -80px;
    }
}