.single-col{
    background: #F4F3F8;
    .img-fluid{
        max-width: 360px;
    }
    .brush-divider{
        position: relative;
        z-index: 5;
        top: 100px;
        
        @include media-breakpoint-up(sm) {
            top: 148px;
        }
        @include media-breakpoint-up(md) {
            top: 132px;
        }
        @include media-breakpoint-up(lg) {
            top:138px;
        }
        @include media-breakpoint-up(xl) {
            top: 160px;
        }
    }
}