form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        padding: 20px 15px 20px 50px;
        box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
        border: none;
        margin-bottom: 15px;
        border-radius: 5px;
        font-size: 2rem;
        line-height: 1;
        @include media-breakpoint-up(md) {
            padding: 20px 15px 20px 70px;
        }
        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }
    }

    textarea {
        height: 210px !important;
    }

    select {
        appearance: none;
    }

    .form-group {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;
        .icon {
            position: absolute;
            top: 20px;
            left: 15px;
            width: 25px;
            height: 25px;
            z-index: 999;

            @include media-breakpoint-up(md) {
                left: 25px;
            }
        }
    }

    label.error,
    .field-error {
        display: block;
        margin-bottom: 20px;
        margin-top: -10px;
        color: red;
        font-size: 1.5rem;
    }
    .form-messages {
        text-align: center;
        &.success {
            color: green;
        }
        &.error {
            color: red;
        }
    }
}