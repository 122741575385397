.two-col-img-text-section {
    .img-text-left-right{
        
    }
    .text-img-left-right{
        padding-top: 50px;
    }
    .img-sub-block{
        max-width: 457px;
        margin:0 auto;
        background: #FFFFFF;
        box-shadow: 0px 60px 80px -32px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        .text{
            padding: 23px 14px;
            font-family: 'Avenir-Next';
            font-weight: 500;
            font-size: 19px;
            line-height: 26px;
            text-align: center;
            letter-spacing: -0.02em;
            color: #000000;
        }
        position: relative;
        z-index: 1;
    }
    .red-stroke{
        bottom: 52px;
        left: 137px;    
        max-width: 292px;
        position: relative;
        @include media-breakpoint-up(sm) {
            left: 260px;
            bottom: 65px;
        }
    }
}