header {
    padding: 15px;
    background-color: transparent;
    &.scrolled {
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
        background-color: $headerbg;
        transition: 0.2s ease-in-out;
    }
}

.header-logo img {
    transition: 0.2s ease-in-out;
    // Inverts the logo colour. You could use the filter options to modify a transparent png logo for the different colour header bg.
    // filter: invert(1);

    // Default to small scrolled size on mobile 
    width: $header-scrolled-width;

    // Turn on the header resize on desktop
    @include media-breakpoint-up(md) {
        width: $header-starting-width;
    }
    .scrolled & {
        // filter: none;
        // Turn on the header resize on desktop
        @include media-breakpoint-up(md) {
            width: $header-scrolled-width;
        }
    }
}

.header-phone {
    line-height: 1.2; 

    .phone-icon {
        padding-right: 17px;
        img,
        svg {
            max-width: 35px;
            width: 100%;
        }
    }


    // Got questions (if it exists)
    span {
        font-size: 19px;
        line-height: 26px;
        align-items: center;
        letter-spacing: -0.02em;
        color: #ED1C24;
    }
    // Phone number text
    em {
        color: #222326;
        font-style: normal;
        font-size: 26px;
        font-family: 'ITC Avant Garde Gothic Pro';
        font-weight: 800;
    }
} 
.hero-centered{
    background: #f5f4f9;
    padding: 60px 0 0 0;
    @include media-breakpoint-up(md) {
        padding: 80px 0 0 0;
    }
    @include media-breakpoint-up(lg) {
        padding: 120px 0 0 0;
    }
    h1{
        max-width: 893px;
    }
    .background-image-container {
        z-index: 0;
        img{
            height: 40%;
            object-fit: contain;
            position: absolute;
            right: 0;
            width: auto;
            bottom: 0;
            @include media-breakpoint-up(md) {
                // width: auto;
                height: 100%;
                object-fit: none;
                // position: absolute;
                right: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            left: 100px;
        }
    }
    .hero-extra-padding{
            margin-top: 4rem !important;
            @include media-breakpoint-up(md) {
                margin-top: 1.5rem !important;
            }
    }
}

.icon-blocks{
 
   .container{
       padding-left: 0;
       padding-right: 0;
   }
    .top-logos{
        margin-bottom: 0px;

        // @include media-breakpoint-up(md) {
        //     margin-bottom: 100px;
        // }
        // padding-bottom: 40px;
        position: relative;
        ul{
            list-style: none;

            display: flex;
            flex-wrap: wrap;

            padding: 40px 20px;
            flex-direction: column;
            align-items: start !important;
            @include media-breakpoint-up(sm) {
            }
            
            @include media-breakpoint-up(md) {
                align-items: center !important;
                flex-direction: row;
            }
            li{
                align-items: center !important;
                display: flex;
                margin: 10px 0 10px 0;

                &:nth-child(1){

                }
                &:nth-child(2){

                }
                &:nth-child(3){

                }
                &:nth-child(4){

                }
            }
            .icon{
                height: 72px;
                padding-right:15px;
                border-right: 0.5px solid #A6A6A6;

                display: flex;
                justify-content: center;
                align-items: center;

                img{
                    width: 57px;
                }

            }
            .text{
                padding-left:15px;
                padding-right: 45px;
                font-family: 'ITC Avant Garde Gothic Pro';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 132.5%;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #222326;

            
            }
        }
    }
}
.hero-brush-divider{
    position: relative;
    z-index: 5;
    top: 15px;
    @include media-breakpoint-up(md) {
        top: 25px;
    }
    @include media-breakpoint-up(lg) {
        top: 45px;
    }
}